export default {
  MARKET_ORGANOGRAM: {
    MARKET_ORGANOGRAM_READ: 'MARKET_ORGANOGRAM_READ',
    MARKET_ORGANOGRAM_WRITE: 'MARKET_ORGANOGRAM_WRITE',
  },
  VAN: {
    VAN_READ: 'VAN_READ',
    VAN_WRITE: 'VAN_WRITE',
  },
  DELIVERY_ASSISTANT: {
    DELIVERY_ASSISTANT_READ: 'DELIVERY_ASSISTANT_READ',
    DELIVERY_ASSISTANT_WRITE: 'DELIVERY_ASSISTANT_WRITE',
  },
  ORDER: {
    ORDER_READ: 'ORDER_READ',
    ORDER_WRITE: 'ORDER_WRITE',
    ORDER_APPROVE: 'ORDER_APPROVE',
    ORDER_APPROVE_FORCE: 'ORDER_APPROVE_FORCE',
  },
  DISCOUNT: {
    DISCOUNT_READ: 'SALE_DISCOUNT_READ',
    DISCOUNT_WRITE: 'SALE_DISCOUNT_WRITE',
  },
  PRICE: {
    PRICE_READ: 'PRICE_READ',
    PRICE_WRITE: 'PRICE_WRITE',
  },
  CHALLAN: {
    CHALLAN_READ: 'CHALLAN_READ',
  },
  INVOICE: {
    INVOICE_READ: 'INVOICE_READ',
  },
  COLLECTION: {
    ORDER_READ: 'ORDER_READ',
    INVOICE_READ: 'INVOICE_READ',
    COLLECTION_READ: 'COLLECTION_READ',
    COLLECTION_WRITE: 'COLLECTION_WRITE',
    COLLECTION_APPROVE: 'COLLECTION_APPROVE',
  },
  COLLECTION_DEPOSIT: {
    COLLECTION_DEPOSIT_READ: 'COLLECTION_DEPOSIT_READ',
    COLLECTION_DEPOSIT_WRITE: 'COLLECTION_DEPOSIT_WRITE',
    COLLECTION_DEPOSIT_APPROVE: 'COLLECTION_DEPOSIT_APPROVE',
  },
  ROUTE: {
    ROUTE_READ: 'ROUTE_READ',
    ROUTE_WRITE: 'ROUTE_WRITE',
  },
  PLAN: {
    PLAN_READ: 'PLAN_READ',
    PLAN_WRITE: 'PLAN_WRITE',
  },
  SALE_FOC: {
    SALE_FOC_READ: 'SALE_FOC_READ',
    SALE_FOC_WRITE: 'SALE_FOC_WRITE',
  },
  SALE_COLLECTION_DISCOUNT: {
    SALE_COLLECTION_DISCOUNT_READ: 'SALE_COLLECTION_DISCOUNT_READ',
    SALE_COLLECTION_DISCOUNT_WRITE: 'SALE_COLLECTION_DISCOUNT_WRITE',
  },
  SAMPLE_INVOICE: {
    SAMPLE_INVOICE_READ: 'SAMPLE_INVOICE_READ',
    SAMPLE_INVOICE_WRITE: 'SAMPLE_INVOICE_WRITE',
    SAMPLE_INVOICE_APPROVE: 'SAMPLE_INVOICE_APPROVE',
  },
  SALE_REPORT_INVOICE_MASTER: {
    SALE_REPORT_INVOICE_MASTER: 'SALE_REPORT_INVOICE_MASTER',
  },
  SALE_REPORT_INVOICE: {
    SALE_REPORT_INVOICE: 'SALE_REPORT_INVOICE',
  },
  SALE_RETURN: {
    SALE_RETURN_READ: 'SALE_RETURN_READ',
    SALE_RETURN_WRITE: 'SALE_RETURN_WRITE',
    SALE_RETURN_APPROVE: 'SALE_RETURN_APPROVE',
  },
  SALE_REPORT_CMR: {
    SALE_REPORT_CMR: 'SALE_REPORT_CMR',
  },
  SALE_REPORT_CMR_FEED: {
    SALE_REPORT_CMR_FEED: 'SALE_REPORT_CMR_FEED',
  },
  SALE_REPORT_BATCH_CHALLAN: {
    SALE_REPORT_BATCH_CHALLAN: 'SALE_REPORT_BATCH_CHALLAN',
  },
  SALE_REPORT_LOCATION_WISE: {
    SALE_REPORT_LOCATION_WISE: 'SALE_REPORT_LOCATION_WISE',
  },
  SALE_REPORT_DUE: {
    SALE_REPORT_DUE: 'SALE_REPORT_DUE',
  },
  SALE_REPORT_PRODUCT_GROUP_WISE: {
    SALE_REPORT_PRODUCT_GROUP_WISE: 'SALE_REPORT_PRODUCT_GROUP_WISE',
  },
  SALE_REPORT_TARGET_VS_APPROVED_ORDER: {
    SALE_REPORT_TARGET_VS_APPROVED_ORDER: 'SALE_REPORT_TARGET_VS_APPROVED_ORDER',
  },
  SALE_REPORT_PSC: {
    SALE_REPORT_PSC: 'SALE_REPORT_PSC',
  },
  SALE_TARGET: {
    SALE_TARGET_READ: 'SALE_TARGET_READ',
    SALE_TARGET_WRITE: 'SALE_TARGET_WRITE',
    SALE_TARGET_APPROVE: 'SALE_TARGET_APPROVE',
  },
  SALE_REPORT_CUSTOMER_PRODUCT: {
    SALE_REPORT_CUSTOMER_PRODUCT: 'SALE_REPORT_CUSTOMER_PRODUCT',
  },
  SALE_REPORT_COLLECTION_BREAK_DOWN: {
    SALE_REPORT_COLLECTION_BREAK_DOWN: 'SALE_REPORT_COLLECTION_BREAK_DOWN',
  },
  SALE_REPORT_PRODUCT_WISE: {
    SALE_REPORT_PRODUCT_WISE: 'SALE_REPORT_PRODUCT_WISE',
  },
  SALE_PRODUCT_REPORT_PRICE: {
    SALE_PRODUCT_REPORT_PRICE: 'SALE_PRODUCT_REPORT_PRICE',
  },
  SALE_REPORT_TARGET: {
    SALE_REPORT_TARGET: 'SALE_REPORT_TARGET',
  },
  SALE_REPORT_COLLECTION_MASTER: {
    SALE_REPORT_COLLECTION_MASTER: 'SALE_REPORT_COLLECTION_MASTER',
  },
  SALE_REPORT_RETURN_MASTER: {
    SALE_REPORT_RETURN_MASTER: 'SALE_REPORT_RETURN_MASTER',
  },
}
