import { createSlice } from '@reduxjs/toolkit'
// ** Initial State
const initialState = {
  suggestions: [],
  bookmarks: [],
  query: '',
}

export const navbarSlice = createSlice({
  name: 'navbar',
  initialState,
  reducers: {
    handleSearchQuery: (state, { payload }) => ({ ...state, query: payload }),
    getBookmarks: (state, { payload: { suggestions, bookmarks } }) => ({
      ...state,
      suggestions,
      bookmarks,
    }),
    updateBookmarks: (state, { payload }) => {
      let objectToUpdate

      // ** find & update object
      // eslint-disable-next-line array-callback-return
      state.suggestions.find((item) => {
        if (item.id === payload.id) {
          const newItem = { ...item };
          newItem.isBookmarked = !item.isBookmarked
          objectToUpdate = newItem;
        }
      })

      // ** Get index to add or remove bookmark from array
      const bookmarkIndex = state.bookmarks.findIndex(
        (x) => x.id === payload.id,
      )

      if (bookmarkIndex === -1) {
        state.bookmarks.push(objectToUpdate)
      } else {
        state.bookmarks.splice(bookmarkIndex, 1)
      }
    },
  },
})

export const { handleSearchQuery, updateBookmarks, getBookmarks } = navbarSlice.actions
export default navbarSlice.reducer
