import { createSlice } from '@reduxjs/toolkit'
import { getUserData } from '@utils'

const user = getUserData()
const INIT_STATE = {
  initURL: '',
  authUser: { user },
  type: '',
}

export const authSlice = createSlice({
  name: 'auth',
  initialState: INIT_STATE,
  reducers: {
    userSignInSuccess: (state, { payload }) => ({
      ...state,
      authUser: payload,
    }),
    userSignOutSuccess: (state, { payload }) => ({
      ...state,
      authUser: null,
      initURL: '/login',
      type: payload.type,
    }),
    setInitUrl: (state, { payload }) => ({ ...state, initURL: payload }),
  },
})

export const { userSignInSuccess, userSignOutSuccess, setInitUrl } = authSlice.actions
export default authSlice.reducer
