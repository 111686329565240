/* eslint-disable no-param-reassign */
import { configureStore } from '@reduxjs/toolkit'
import createRootReducer from './reducers'

const middleware = (getDefaultMiddleware) => getDefaultMiddleware({
  immutableCheck: false,
  serializableCheck: false,
  thunk: true,
})

const rootReducer = (state, action) => {
  if (action.type === 'auth/userSignOutSuccess') {
    state = {}
  }
  if (action.type === 'handleCompanyChange') {
    state = {
      auth: state.auth,
      navbar: state.navbar,
    }
  }
  return createRootReducer(state, action)
}

const store = configureStore({
  reducer: rootReducer,
  middleware,
  devTools: process.env.NODE_ENV !== 'production',
})

export default store
