import { createSlice } from '@reduxjs/toolkit';
import { CONST_DATA_KEY } from '@src/utility/Constants';

const initialState = {
  FIREBASE: {
    apiKey: '',
    databaseURL: '',
    projectId: '',
    ref: '',
    token: '',
  },
};

Object.keys(CONST_DATA_KEY).map(
  // eslint-disable-next-line no-return-assign
  (key) => (initialState[CONST_DATA_KEY[key]] = { ...initialState[key] }),
);
export const constantSlice = createSlice({
  name: 'constant',
  initialState,
  reducers: {
    loadConstant: (state, { payload }) => ({
      ...state,
      [payload.key]: payload.data,
    }),
  },
});

export const { loadConstant } = constantSlice.actions;
export default constantSlice.reducer;
