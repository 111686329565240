// ** ThemeConfig Import
import themeConfig from '@configs/themeConfig'
import { createSlice } from '@reduxjs/toolkit'

// ** Returns Initial Menu Collapsed State
const initialMenuCollapsed = () => {
  const item = window.localStorage.getItem('menuCollapsed')
  //* * Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : themeConfig.layout.menu.isCollapsed
}

// ** Returns Initial Layout Skin
const initialLayoutSkin = () => {
  const item = window.localStorage.getItem('skin')
  //* * Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : themeConfig.layout.skin
}

// ** Initial State
const INIT_STATE = {
  skin: initialLayoutSkin(),
  isRTL: themeConfig.layout.isRTL,
  menuCollapsed: initialMenuCollapsed(),
  menuHidden: themeConfig.layout.menu.isHidden,
  contentWidth: themeConfig.layout.contentWidth,
}

export const layoutSlice = createSlice({
  name: 'layout',
  initialState: INIT_STATE,
  reducers: {
    handleContentWidth: (state, { payload }) => ({
      ...state,
      contentWidth: payload,
    }),
    handleMenuCollapsed: (state, { payload }) => {
      window.localStorage.setItem('menuCollapsed', payload)
      return { ...state, menuCollapsed: payload }
    },
    handleMenuHidden: (state, { payload }) => ({
      ...state,
      menuHidden: payload,
    }),
    handleRTL: (state, { payload }) => ({ ...state, isRTL: payload }),
    handleSkin: (state, { payload }) => ({ ...state, skin: payload }),
  },
})

export const {
  handleContentWidth,
  handleMenuCollapsed,
  handleMenuHidden,
  handleRTL,
  handleSkin,
} = layoutSlice.actions

export default layoutSlice.reducer
