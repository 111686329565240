import { combineReducers } from 'redux'
//
import chat from '@src/views/apps/chat/store/reducer'
import todo from '@src/views/apps/todo/store/reducer'
import users from '@src/views/apps/user/store/reducer'
import email from '@src/views/apps/email/store/reducer'
import invoice from '@src/views/apps/invoice/store/reducer'
import calendar from '@src/views/apps/calendar/store/reducer'
import ecommerce from '@src/views/apps/ecommerce/store/reducer'
import dataTables from '@src/views/tables/data-tables/store/reducer'
import ConstData from './firebase/ConstData';
import navbar from './navbar'
import layout from './layout'
import loading from './loading'
import common from './common'
import auth from './auth'
import pagination from './pagination'

const createRootReducer = combineReducers({
  auth,
  loading,
  common,
  layout,
  navbar,
  pagination,
  chat,
  todo,
  users,
  email,
  invoice,
  calendar,
  ecommerce,
  dataTables,
  constData: ConstData,
})

export default createRootReducer
