// https://dev.to/jam3/managing-env-variables-for-provisional-builds-h37
const {
  REACT_APP_SERVER_URL,
  REACT_APP_ID,
  REACT_APP_SENTRY_DSN,
  REACT_APP_ENV,
  REACT_APP_SENTRY_APP_ENV,
  REACT_APP_REPORT_URL,
} = process.env

export const CONFIG = {
  APP_ENV: REACT_APP_ENV,
  SENTRY_ENV: REACT_APP_SENTRY_APP_ENV,
  SERVER_URL: REACT_APP_SERVER_URL,
  REPORT_URL: REACT_APP_REPORT_URL,
  API_ID: REACT_APP_ID,
  SENTRY_DSN: REACT_APP_SENTRY_DSN,
}
